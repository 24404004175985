import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Poi} from '../models';

@Injectable({
  providedIn: 'root',
})
export class PoiService {

  constructor(public db: AngularFirestore) {
  }

  public getAll(): Observable<Poi[]> {
    return this.db.collection('/pois').snapshotChanges().pipe(
        map((resp: any) => resp.map(item => this.parseFromFireBase(item.payload.doc))),
    );
  }

  private parseFromFireBase(item: any): Poi {
    const data = item.data();
    const id = item.id;
    const lat = data.coordinates.latitude;
    const lng = data.coordinates.longitude;
    const coordinates = {lat, lng};
    return new Poi({id, ...data, coordinates});
  }

}

