import {TrailsActions} from './trails.actions';
import {TrailsState} from './trails.state';

export function trailsReducer(state: TrailsState = new TrailsState(), action: any) {
  switch (action.type) {
    case TrailsActions.GET_ALL_SUCCESS:
      return {...state, all: action.payload};
    case TrailsActions.SET_GEO_LOCATION:
      return {...state, geoLocation: action.payload};
    case TrailsActions.SET_GEO_ZOOM:
      return {...state, zoom: action.payload};
    default:
      return state;
  }
}

