import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {Trail} from '../models';
import {TrailsService} from '../services/trails.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {TrailsActions} from './trails.actions';

@Injectable()
export class TrailsEffects {

  constructor(private actions$: Actions, private trailsService: TrailsService) {
  }

  @Effect()
  getAll$ = this.actions$.pipe(
      ofType(TrailsActions.GET_ALL),
      map((action: any) => ({...action.payload})),
      switchMap((payload) =>
          this.trailsService.getAll().pipe(
              map((trails: Trail[]) => ({type: TrailsActions.GET_ALL_SUCCESS, payload: trails})),
              catchError((error) => of({type: TrailsActions.GET_ALL_FAILURE, payload: error.message})),
          )),
  );

}
