import {Component} from '@angular/core';
import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {AuthActions} from './auth/store/auth.actions';
import {isLogged} from './auth/store/auth.selectors';
import {PoisActions} from './pois/store/pois.actions';
import {AppState} from './store/app.state';
import {TrailsActions} from './trails/store/trails.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {

  public isLogged$: Observable<boolean>;


  constructor(
      private store: Store<AppState>,
      private platform: Platform,
      private splashScreen: SplashScreen,
      private statusBar: StatusBar,
      private translate: TranslateService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.isLogged$ = this.store.select(isLogged);

    //@todo take language from the device
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.store.dispatch({type: TrailsActions.GET_ALL});
    this.store.dispatch({type: PoisActions.GET_ALL});
  }

  public signOut() {
    this.store.dispatch({type: AuthActions.SIGN_OUT});
  }
}
