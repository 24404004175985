import {
  GoogleMapOptions,
  MarkerClusterOptions,
  MarkerLabel
} from '@ionic-native/google-maps';

const LABEL_OPTIONS: MarkerLabel = {
  bold: true,
  fontSize: 14,
  color: 'white',
  italic: false
};

export const CLUSTER_OPTIONS_IOS: MarkerClusterOptions = {
  icons: [
    { min: 3, max: 50, url: "www/assets/imgs/m1.png", anchor: { x: 16, y: 16 }, label: LABEL_OPTIONS },
    { min: 50, max: 100, url: "www/assets/imgs/m2.png", anchor: { x: 24, y: 24 }, label: LABEL_OPTIONS },
    { min: 100, url: "www/assets/imgs/m3.png", anchor: { x: 32, y: 32 }, label: LABEL_OPTIONS }
  ],
  markers: [],
  boundsDraw: false,
  maxZoomLevel: 13
};

export const CLUSTER_OPTIONS_ANDROID: MarkerClusterOptions = {
  icons: [
    { min: 3, max: 50, url: "./assets/imgs/m1.png", anchor: { x: 16, y: 16 }, label: LABEL_OPTIONS },
    { min: 50, max: 100, url: "./assets/imgs/m2.png", anchor: { x: 24, y: 24 }, label: LABEL_OPTIONS },
    { min: 100, url: "./assets/imgs/m3.png", anchor: { x: 32, y: 32 }, label: LABEL_OPTIONS }
  ],
  markers: [],
  boundsDraw: false,
  maxZoomLevel: 13
};

export const MAP_OPTIONS: GoogleMapOptions = {
  controls: {
    compass: true,
    scroll: true,
    tilt: true,
    zoom: true,
    rotate: true,
    myLocationButton: false,
    myLocation: true,
    mapToolbar: true
  },

  gestures: {
    scroll: true,
    tilt: true,
    zoom: true,
    rotate: true
  },
  camera: {
    target: {
      lat: 52.5200,
      lng: 13.4050
    },
    zoom: 12,
    tilt: 0
  },
  preferences: {
    building: false
  }
};


export enum MapType {
  ROADMAP = 'roadmap',
  HYBRID = 'hybrid',
  SATELLITE = 'satellite',
  TERRAIN = 'terrain'
}
