import {Injectable} from '@angular/core';
import {Geolocation, GeolocationOptions, Geoposition} from '@ionic-native/geolocation/ngx';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppState} from '../store/app.state';

@Injectable({
  providedIn: 'root',
})
export class GeoService {

  private geoSubscription: Observable<Geoposition>;

  private options: GeolocationOptions = {
    enableHighAccuracy: true,
    maximumAge: 30 * 1000,
    timeout: 20 * 1000,
  };

  constructor(private geolocation: Geolocation, private store: Store<AppState>) {

    console.log('init geo');

    this.geolocation.getCurrentPosition(this.options).then((resp) => {
      console.log('Geo pos', resp);
      // resp.coords.latitude
      // resp.coords.longitude
    }).catch((error) => {
      console.log('Error getting location', error);
    });

    this.geoSubscription = this.geolocation.watchPosition(this.options);

    this.geoSubscription.subscribe((data) => {
      console.log('Geo sub', data);
      // this.store.dispatch({type: TrailsActions.SET_GEO_LOCATION, payload: new GeoPoint(data.coords.latitude, data.coords.longitude)});
    });

  }
}
