import {GeoPoint} from './geoPoint.model';

export class Trail {
  public id: string;
  public name: string;
  public description: string;
  public isActive: boolean;
  public coordinates: GeoPoint[];

  constructor(trail: any = null) {
    this.id = '';
    this.name = '';
    this.description = '';
    this.isActive = true;
    this.coordinates = [];

    if (trail) {
      this.id = trail.id;
      this.name = trail.name;
      this.description = trail.description;
      this.isActive = trail.isActive;
      this.coordinates = trail.coordinates.map(c => new GeoPoint(c.lng, c.lat));
    }
  }

  public getStartPosition(): GeoPoint {
    return this.coordinates[0];
  }

  public getEndPosition(): GeoPoint {
    return this.coordinates[this.coordinates.length - 1];
  }

  deserialize() {
    return {
      // id: this.id,
      name: this.name,
      description: this.description,
      isActive: this.isActive,
      coordinates: this.coordinates.map(c => c.deserialize()),
    };
  }
}
