import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Trail} from '../models';

@Injectable({
  providedIn: 'root',
})
export class TrailsService {

  constructor(public db: AngularFirestore) {
  }

  public getAll(): Observable<Trail[]> {
    return this.db.collection('/trails').snapshotChanges().pipe(
        map((resp: any) => resp.map(item => this.parseFromFireBase(item.payload.doc))),
    );
  }

  public getById(id: string): Observable<Trail> {
    return this.db.collection('/trails').doc(id).snapshotChanges().pipe(
        map((item: any) => this.parseFromFireBase(item.payload)),
    );
  }

  private parseFromFireBase(item: any): Trail {
    const data = item.data();
    const id = item.id;
    const coordinates = data.coordinates.map(i => ({lat: i._lat, lng: i._long}));
    return new Trail({id, ...data, coordinates});
  }

}

