import {Injectable, NgZone} from '@angular/core';
import {Events} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {MAP_OPTIONS} from '../../data/map-options';
import {AppState} from '../store/app.state';
import {GeoPoint} from '../trails/models';
import {TrailsActions} from '../trails/store/trails.actions';


@Injectable()
export class GeolocationService {

  private geolocationOptions = {
    enableHighAccuracy: true,
    maximumAge: 30 * 1000,
    timeout: 20 * 1000,
  };

  private _data: any = {
    position: {},
    hasPosition: false,
    status: false,
    initiated: false,
    errorCode: 0,
  };

  constructor(private events: Events, private zone: NgZone, private store: Store<AppState>) {
    this.watchPosition((apiPosition: Position) => {
      MAP_OPTIONS.camera.target.lat = apiPosition.coords.latitude;
      MAP_OPTIONS.camera.target.lng = apiPosition.coords.longitude;
      this.watchPositionListener(apiPosition.coords.latitude, apiPosition.coords.longitude);
    }, (error) => {
      this.zone.run(() => {
        this._data.status = false;
        this._data.initiated = true;
        this._data.errorCode = error.code;
      });
      console.log('Error getting location', error);
    });
  }

  public get data() {
    return this._data;
  };

  public get position() {
    return this._data.position;
  }

  public get hasPosition() {
    return this._data.hasPosition;
  }

  public get status() {
    return this._data.status;
  }

  public get initiated() {
    return this._data.initiated;
  }

  private watchPosition(successCallback, errorCallback) {
    navigator.geolocation.watchPosition(successCallback, errorCallback, this.geolocationOptions);
  }

  private watchPositionListener(lat: number, lng: number) {
    this.zone.run(() => {
      this._data.position = {lat: lat, lng: lng};
      this._data.hasPosition = true;
      this._data.status = true;
      this._data.initiated = true;
      this._data.errorCode = 0;
    });
    this.events.publish('map:position', this.position);
    this.store.dispatch({type: TrailsActions.SET_GEO_LOCATION, payload: new GeoPoint(lat, lng)});
  }

}
