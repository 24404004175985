import {AuthState} from './auth.state';
import {AuthActions} from './auth.actions';

export function authReducer(state: AuthState = new AuthState(), action: any) {
  switch (action.type) {
    case AuthActions.SET_AUTH:
      return {...state, ...action.payload};
    case AuthActions.SIGN_IN:
      return {...state, isLoading: true, error: ''};
    case AuthActions.SIGN_IN_FAILURE:
      return {...state, isLoading: false, error: action.payload};
    case AuthActions.SIGN_IN_SUCCESS:
      return {...state, user: action.payload, isLoading: false};
    case AuthActions.SIGN_UP_SUCCESS:
      return {...state, user: action.payload, isLoading: false};
    case AuthActions.SIGN_UP_FAILURE:
      return {...state, isLoading: false, error: action.payload};
    case AuthActions.SIGN_OUT:
      return {};
    default:
      return state;
  }
}

