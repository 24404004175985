import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {Poi} from '../models';
import {catchError, map, switchMap} from 'rxjs/operators';
import {PoiService} from '../services/pois.service';
import {PoisActions} from './pois.actions';

@Injectable()
export class PoisEffects {

  constructor(private actions$: Actions, private api: PoiService) {
  }

  @Effect()
  getAll$ = this.actions$.pipe(
      ofType(PoisActions.GET_ALL),
      map((action: any) => ({...action.payload})),
      switchMap((payload) =>
          this.api.getAll().pipe(
              map((pois: Poi[]) => ({type: PoisActions.GET_ALL_SUCCESS, payload: pois})),
              catchError((error) => of({type: PoisActions.GET_ALL_FAILURE, payload: error.message})),
          )),
  );

}
