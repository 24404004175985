import {createSelector} from '@ngrx/store';
import {AppState} from '../../store/app.state';

export const authObj = (appState: AppState) => appState.auth;

export const isLogged = createSelector(authObj, (auth: any) => (auth && auth.user));

export const getEmail = createSelector(authObj, (auth: any) => (auth && auth.user) ? auth.user.email : '');

export const isLoading = createSelector(authObj, (auth: any) => auth.isLoading);

export const getErrors = createSelector(authObj, (auth: any) => auth.error ? [auth.error] : []);

