import {Injectable} from '@angular/core';
import {LoadingController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

//declare var isCordovaApp;

@Injectable()
export class UtilsService {

  constructor(private loadingCtrl: LoadingController,
              private platform: Platform,
              private translate: TranslateService,
              // private iab: InAppB
  ) {
  }

  public isNative(): boolean {
    return this.platform.is('cordova');
  }


  public getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ''])[1].replace(/\+/g, '%20')) || null;
  }

  public objectToArray(obj) {
    return Object.keys(obj).map(key => {
      return {key: key, value: obj[key]};
    });
  }


  /* platform */

  public isIOS(): boolean {
    return this.platform.is('ios');
  }

  public isAndroid(): boolean {
    return this.platform.is('android');
  }

  public isDesktop(): boolean {
    return this.platform.is('desktop');
  }

  /* upload */

  // public static promiseMap(items, mapper) {
  //   const reducer = (promise, item) =>
  //       promise.then(mappedItems => mapper(item).then(res => mappedItems.concat([res])));
  //   return items.reduce(reducer, Promise.resolve([]));
  // }

  // public presentLoader(content?: string, timeout = 10000): Promise<() => Promise<any>> {
    // let isDismissed = false;
    //
    // const loader = this.loadingCtrl.create({
    //   content: content ? content : '',
    // });
    //
    // const dismissLoader = (timeoutExceeded = false) => {
    //   if (isDismissed) {
    //     return Promise.resolve();
    //   }
    //
    //   if (timeoutExceeded) {
    //     console.error('Loader timeout exceeded');
    //   }
    //
    //   isDismissed = true;
    //
    //   return loader.dismiss();
    // };
    //
    // return loader.present()
    //     .then(() => {
    //       if (timeout > 0) {
    //         setTimeout(_.partial(dismissLoader, true), timeout);
    //       }
    //     })
    //     .then(() => dismissLoader);
  // }

  /* In App Browser */

  public openIAB(url: string, zoom: string = 'no') {
    // this.iab.create(url, '_blank', 'location=yes,zoom=' + zoom + ',footer=no,closebuttoncaption=' + this.translate.instant('general.close') + ',closebuttoncolor=#3c9e6d,toolbarcolor=#ffffff,hidenavigationbuttons=yes,hideurlbar=yes');
  }

  public openSystem(url) {
    // this.iab.create(url, '_system');
  }

}
