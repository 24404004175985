import {PoisActions} from './pois.actions';
import {PoisState} from './pois.state';

export function poisReducer(state: PoisState = new PoisState(), action: any) {
  switch (action.type) {
    case PoisActions.GET_ALL_SUCCESS:
      return {...state, all: action.payload};
    default:
      return state;
  }
}

