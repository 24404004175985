import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {User} from '../models';
import {AuthActions} from './auth.actions';
import {Router} from '@angular/router';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

@Injectable()
export class AuthEffects {

  constructor(private router: Router, private actions$: Actions, private afAuth: AngularFireAuth) {
  }

  @Effect({dispatch: false})
  signOut$ = this.actions$.pipe(
      ofType(AuthActions.SIGN_OUT),
      tap(() => this.router.navigate(['/auth/sign-in'])),
  );

  @Effect({dispatch: false})
  signInSuccess$ = this.actions$.pipe(
      ofType(AuthActions.SIGN_IN_SUCCESS),
      tap(() => this.router.navigate(['/home'])),
  );

  @Effect({dispatch: false})
  signUpSuccess$ = this.actions$.pipe(
      ofType(AuthActions.SIGN_UP_SUCCESS),
      tap(() => this.router.navigate(['/home'])),
  );

  @Effect()
  signUp$ = this.actions$.pipe(
      ofType(AuthActions.SIGN_UP),
      map((action: any) => ({...action.payload})),
      switchMap((payload) => {
        return from(this.signUp(payload)).pipe(
            map((response: any) => {
              if (!response || !response.user) {
                throw response.error;
              }
              return {type: AuthActions.SIGN_UP_SUCCESS, payload: new User(response.user)};
            }),
            catchError((error) => of({type: AuthActions.SIGN_UP_FAILURE, payload: error.message})),
        );
      }),
  );

  @Effect()
  signIn$ = this.actions$.pipe(
      ofType(AuthActions.SIGN_IN),
      map((action: any) => ({...action.payload})),
      switchMap((payload) => {
        return from(this.signIn(payload)).pipe(
            map((response: any) => {
              if (!response || !response.user) {
                throw response.error;
              }
              return {type: AuthActions.SIGN_IN_SUCCESS, payload: new User(response.user)};
            }),
            catchError((error) => of({type: AuthActions.SIGN_IN_FAILURE, payload: error.message})),
        );
      }),
  );

  private signIn({email, password}) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password).catch(error => ({error}));
  }

  private signUp({email, password}) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password).catch(error => ({error}));
  }


}
