import {ILatLng} from '@ionic-native/google-maps';
import * as fb from 'firebase';

export class GeoLocation implements ILatLng {
  public lat: number;
  public lng: number;
  public hasError: boolean;

  constructor(lat: number, lng: number, hasError: boolean) {
    this.lat = lat;
    this.lng = lng;
    this.hasError = hasError;
  }


}
