import {ILatLng} from '@ionic-native/google-maps';
import * as fb from 'firebase';

export class GeoPoint implements ILatLng {
  public lat: number;
  public lng: number;

  constructor(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }

  public deserialize(): fb.firestore.GeoPoint {
    return new fb.firestore.GeoPoint(this.lat, this.lng);
  }
}
