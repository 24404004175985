export class AuthActions {
  static readonly SIGN_IN = '[AUTH] SIGN_IN';
  static readonly SIGN_IN_SUCCESS = '[AUTH] SIGN_IN_SUCCESS';
  static readonly SIGN_OUT = '[AUTH] SIGN_OUT';
  static readonly SET_AUTH = '[AUTH] SET_AUTH';
  static readonly SIGN_IN_FAILURE = '[AUTH] SIGN_IN_FAILURE';
  static readonly SIGN_UP = '[AUTH] SIGN_UP';
  static readonly SIGN_UP_SUCCESS = '[AUTH] SIGN_UP_SUCCESS';
  static readonly SIGN_UP_FAILURE = '[AUTH] SIGN_UP_FAILURE';
}
