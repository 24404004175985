import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {AngularFireModule, FirebaseOptionsToken} from '@angular/fire';
import {AngularFirestoreModule, FirestoreSettingsToken} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {Geolocation} from '@ionic-native/geolocation/ngx';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {EffectsModule} from '@ngrx/effects';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule, TranslateModuleConfig} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {StorageSyncEffects, storageSync} from 'ngrx-6-store-ionic-storage';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {environment} from '../environments/environment';
import {GeoService} from './services/geo.service';
import {GeolocationService} from './services/geolocation.service';
import {NativeMapService} from './services/native-map.service';
import {UtilsService} from './services/utils-service';
import {effects} from './store/app.effects';
import {reducers} from './store/app.reducers';


/* I18n */
export function createTranslateLoader(http: HttpClient) {
  const dateTime = new Date().getTime();
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?cb=${dateTime}`);
}

const translateConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient],
  },
};

const I18N_MODULES = [TranslateModule.forRoot(translateConfig), TranslateModule];

/* Firebase */

const FIREBASE_MODULES = [
  AngularFireModule.initializeApp(environment.firebaseConfig),
  AngularFirestoreModule,
  AngularFireAuthModule,
  AngularFireStorageModule,
];

const FIREBASE_PROVIDERS = [
  {provide: FirebaseOptionsToken, useValue: environment.firebaseConfig},
  {provide: FirestoreSettingsToken, useValue: {}},
];

/* Store */

export function onSyncError(err) {
  console.log(err);
}

export const storageSyncReducer = storageSync({
  keys: ['auth'],   // Only sync the `collection` state
  hydratedStateKey: 'hydrated', // Add this key to the state
  onSyncError: onSyncError,      // If a sync fails
});

export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

export const metaReducers: MetaReducer<any, any>[] = [storageMetaReducer];

const STORE_MODULES = [
  StoreModule.forRoot(reducers, {metaReducers}),
  EffectsModule.forRoot(effects),
  StoreDevtoolsModule.instrument(),
];


const APP_PROVIDERS = [
  NativeMapService,
  // NotificationPushwooshService,
  // ProfileService,
  // StorageService,
  GeolocationService,
  GeoService,
  UtilsService,
  // { provide: ErrorHandler, useClass: SentryIonicErrorHandler }
];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    ...I18N_MODULES,
    IonicModule.forRoot(),
    AppRoutingModule,
    ...FIREBASE_MODULES,
    ...STORE_MODULES,

  ],
  providers: [
    Geolocation,
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    ...FIREBASE_PROVIDERS,
    ...APP_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
