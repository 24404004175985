import {Injectable, ApplicationRef} from '@angular/core';
import {Events, Platform} from '@ionic/angular';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {debounceTime} from 'rxjs/operators';
import {
  GoogleMaps,
  GoogleMap,
  MarkerOptions,
  MarkerCluster, GoogleMapsEvent, Environment,
} from '@ionic-native/google-maps';

import {TranslateService} from '@ngx-translate/core';
import {CLUSTER_OPTIONS_ANDROID, CLUSTER_OPTIONS_IOS, MAP_OPTIONS} from '../../data/map-options';
import {GeolocationService} from './geolocation.service';
import {UtilsService} from './utils-service';


@Injectable()
export class NativeMapService {

  public loading: boolean = false;
  public mapReady: boolean = false;
  public map: GoogleMap;
  private userPosition;
  private markercluster: MarkerCluster;
  private boundsChangeSubject: Subject<any> = new Subject<any>();
  private userView: boolean = false;
  public currentZoom: number = MAP_OPTIONS.camera.zoom;
  public minimumZoom: number = 12;
  public mapAnimationFinished: boolean = false;
  public removeMap: boolean = true;

  private _findingsOnMap: Array<any> = new Array<any>();
  private removeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private subscription;
  public text: any;

  constructor(private events: Events,
              private geolocationService: GeolocationService,
              private platform: Platform,
              private utilsService: UtilsService,
              private translate: TranslateService,
              private appRef: ApplicationRef) {

    this.text = {
      unsaved: this.translate.instant('general.unsaved'),
      date: this.translate.instant('findingsMap.infoWindow.date'),
      location: this.translate.instant('findingsMap.infoWindow.location'),
      causeOfDeath: this.translate.instant('findingsMap.infoWindow.causeOfDeath'),
    };

    // uncomment for platform browser

    try {
      Environment.setEnv({
        'API_KEY_FOR_BROWSER_RELEASE': 'AIzaSyDLQYqmhLgWx3Rs2aV0bqHRmPXfxuBgI5Y',
        'API_KEY_FOR_BROWSER_DEBUG': 'AIzaSyDLQYqmhLgWx3Rs2aV0bqHRmPXfxuBgI5Y',
      });
    } catch (err) {
      console.log(err);
    }


    // this.geolocationService.init();

    // this.events.subscribe('map:position', (position: Position) => {
    //   this.userPosition = position;
    // });
    //
    // this.boundsChangeSubject.pipe(debounceTime(600))
    //     .subscribe(e => {
    //       this.currentZoom = e.zoom;
    //       this.appRef.tick();
    //
    //       if (e.zoom >= this.minimumZoom) {
    //         this.loading = true;
    //         this.appRef.tick();
    //
    //         this.loading = false;
    //         this.appRef.tick();
    //
    //
    //         // this.findingsService.fetchFindings(new GeoBox(e.southwest.lng, e.northeast.lng, e.southwest.lat, e.northeast.lat), this.userView)
    //         //     .then(funde => {
    //         //       this.markercluster.addMarkers(this.convertMarkersFromFunds(funde, false));
    //         //       this.loading = false;
    //         //       this.appRef.tick();
    //         //     }, () => {
    //         //       this.loading = false;
    //         //       this.appRef.tick();
    //         //     });
    //       }
    //     });

  }

  public init(divId: string, userFindings: boolean, showPointer: boolean, zoom: number = 14, position = null) {
    this.initMap(divId, userFindings, showPointer, zoom, position);
  }


  public initMap(divId: string, userFindings: boolean, showPointer: boolean, zoom: number = 0, position) {
    this.unsubscribeSafe(this.subscription);
    this._findingsOnMap = [];
    this.userView = userFindings;

    if (zoom > 0) {
      MAP_OPTIONS.camera.zoom = zoom;
    }

    this.map = GoogleMaps.create(divId, MAP_OPTIONS);

    if (!showPointer) {
      this.removeMap = true;
      this.addMarkerCluster();
      this.map.on(GoogleMapsEvent.CAMERA_MOVE_END).subscribe(params => {
        this.boundsChangeSubject.next(params[0]);
      });
    } else {
      this.loading = false;
    }

    this.map.on(GoogleMapsEvent.MAP_READY).subscribe(params => {
      this.mapReady = true;
      setTimeout(() => {
        if (this.userPosition) {
          this.moveToUserLocation(position);
        } else {
          setTimeout(() => {
            this.moveToUserLocation(position);
          }, 2000);
        }
      }, 500);
    });
  }

  public remove() {
    if (this.removeMap) {
      this.loading = false;
      this.appRef.tick();
      this.mapReady = false;
      this.removeSubject.next(true);
      if (this.map.getDiv()) {
        this.map.remove()
            .then(() => this.removeSubject.next(false));
      } else {
        this.removeSubject.next(false);
      }
    }
  }

  public toggleUserMode() {
    this.userView = !this.userView;
    this.map.clear().then(() => this.addMarkerCluster());
  }

  public setUserMode(mode: boolean) {
    this.userView = mode;
    this.map.clear().then(() => this.addMarkerCluster());
  }

  public toggleMapMode(roadMap: boolean) {
    if (roadMap) {
      this.map.setMapTypeId('MAP_TYPE_SATELLITE');
    } else {
      this.map.setMapTypeId('MAP_TYPE_NORMAL');
    }
  }

  public moveToUserLocation(position) {
    this.updatePosition(position ? position : this.userPosition);
  }

  public getPointerPosition() {
    try {
      return this.map.getCameraTarget();
    } catch (err) {
      return this.userPosition;
    }
  }

  private addMarkerCluster() {
    this.map.addMarkerCluster(this.getClusterOptions()).then((_markercluster: MarkerCluster) => {
      this.markercluster = _markercluster;
      // this.markercluster.addMarkers(this.convertMarkersFromFunds(this.findingsService.findings, true));
    });
  }

  private updatePosition(position) {
    this.map.animateCamera({
      target: position,
      tilt: 0,
      bearing: 0,
      duration: 1000,
    });
  }

  private convertToMarkerOptions(animal: any): MarkerOptions {
    this._findingsOnMap.push(animal);
    if (animal.unsaved) {
      return <MarkerOptions>{
        icon: 'orange',
        position: {
          lat: animal.lat,
          lng: animal.lon,
        },
        title: this.text.unsaved,
        snippet: `${animal.funddatum}`,
      };
    } else {
      let snippet = `${this.text.causeOfDeath}: ${animal.ursache}\n${this.text.location}: ${animal.fundort}\n${this.text.date}: ${animal.funddatum}`;
      if (this.utilsService.isIOS()) {
        snippet = `${this.text.causeOfDeath}: ${animal.ursache}, ${this.text.location}: ${animal.fundort}, ${this.text.date}: ${animal.funddatum}`;
      }

      return <MarkerOptions>{
        icon: 'red',
        position: {
          lat: animal.lat,
          lng: animal.lon,
        },
        title: animal.art,
        snippet: snippet,
      };
    }

  }

  private convertMarkersFromFunds(funde: Array<any>, skipUniquenessCheck: boolean): Array<MarkerOptions> {
    let markers: Array<MarkerOptions> = new Array<MarkerOptions>();
    if (!funde || !funde.length) {
      return markers;
    }

    return markers;
  }

  private getClusterOptions() {
    return this.platform.is('ios') ? CLUSTER_OPTIONS_IOS : CLUSTER_OPTIONS_ANDROID;
  }

  private unsubscribeSafe(subs) {
    if (subs) {
      subs.unsubscribe();
    }
  }
}
