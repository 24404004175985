import {MarkerOptions} from '@ionic-native/google-maps';
import {GeoPoint} from '../../trails/models';
import {PoiType} from './poiType.enum';

export class Poi {
  public id: string;
  public name: string;
  public photo: string;
  public address: string;
  public bookingComUrl: string;
  public coordinates: GeoPoint;
  public type: PoiType;
  public isActive: boolean;
  public description: string;

  constructor(poi: any = null) {
    this.id = '';
    this.name = '';
    this.photo = '';
    this.address = '';
    this.bookingComUrl = '';
    this.isActive = false;
    this.description = '';
    this.coordinates = new GeoPoint(51.673858, 7.815982);

    if (poi) {
      this.id = poi.id;
      this.name = poi.name;
      this.photo = poi.photo;
      this.address = poi.address;
      this.description = poi.description;
      this.isActive = poi.isActive;
      this.bookingComUrl = poi.bookingComUrl;
      this.type = poi.type;
      this.coordinates = new GeoPoint(poi.coordinates.lat, poi.coordinates.lng);
    }
  }

  deserialize() {
    return {
      name: this.name,
      description: this.description,
      isActive: this.isActive,
      type: this.type,
      coordinates: this.coordinates.deserialize(),
    };
  }

  toMarker(): MarkerOptions {

    const position = this.coordinates;
    const title = this.name;
    const icon = this.getIcon();
    const visible = true;

    return {
      position, title, visible
    };

  }

  private getIcon() {
    return {
      url: `assets/icon_${this.type.toLowerCase()}_24.png`,
      size: {
        width: 24,
        height: 24,
      },
    };
  }
}
