export class User {
  public id: string;
  public email: string;

  constructor(user: any = null) {
    this.id = '';
    this.email = '';

    if (user) {
      this.id = user.uid;
      this.email = user.email;
    }
  }

}
